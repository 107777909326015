import React from "react"
import Layout from "../../../components/sermonLayoutSurprise"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout sermonSrc="https://www.youtube.com/embed//ak8MjY8IAcg">
    <SEO title="Hard Knocks - A Winning Christmas" />
  </Layout>
)

export default SermonPost
